*{ margin:0; padding:0;}
html.lenis { height: auto; }
.lenis.lenis-smooth { scroll-behavior: auto; }
.lenis.lenis-smooth [data-lenis-prevent] { overscroll-behavior: contain;}
.lenis.lenis-stopped {overflow: hidden;}
.lenis.lenis-scrolling iframe {pointer-events: none;}

@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-Thin.ttf');
  font-weight:100;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-ExtraLight.ttf');
  font-weight:200;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-Light.ttf');
  font-weight:300;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-Regular.ttf');
  font-weight:400;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-Medium.ttf');
  font-weight:500;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-SemiBold.ttf');
  font-weight:600;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-Bold.ttf');
  font-weight:700;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-ExtraBold.ttf');
  font-weight:800;
}
@font-face {
  font-family: "Avrile";
  src: url('/public/fonts/AvrileSans-Black.ttf');
  font-weight:900;
}

.h111{ font-family: "Avrile"; font-weight:700; color: #000000 }

/* @import url('https://fonts.cdnfonts.com/css/avrile-sans'); */

.font_1,label,.btn { font-family:'Avrile Sans', sans-serif;}
.font_2 { font-family:'Playfair Display SC', serif;}
.font_3 { font-family: 'Playfair Display', serif; }
.font_4 { font-family: 'Great Vibes', cursive;}

body { margin: 0; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;scroll-behavior: smooth;}

h1,h2,h3,h4 {font-family:'Playfair Display', serif;}
address {font-family: 'Avrile Sans', sans-serif; }
p {font-family: "Avrile";color:var(--grey_p); font-size: 1.3rem; text-wrap: balance;}

:root{
  --gray:#707070;
  --grey_p:#575757;
  --white:#ffff;
  --black:#000000;
  --magenta:#986585;
  --brand_color:#B4A365;
  --title_headings:#86436D;
  --title:#147287;
  --banner_object:#394E47;
  --brand_color_2:#6A7866;
  --brand_dark:#DECC89;
}

.black {color:var(--black); }

.nav-link { color: var(--brand_color);}  
.nav a.active{color: blue;}

li a{text-decoration: none; color: var(--brand_color); }  header .nav a.active { color:#2A1A18; font-weight:400;}
li a {display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out;}

/**** Video play button ******/
.play { position:absolute; z-index: +55; width:10%;}



/************ Footer CSS ************/
footer { background-color:var(--brand_color_2); border-top:2px solid var(--gray);
  & p {margin-bottom:0;}
  & a {all:unset; }
  & a:hover { cursor:pointer;} 
}
.fssai img {padding-left:5rem; width: 180px;}
.lead { font-weight:normal; color: #DECC89; padding-left:5rem; padding-top:1%; font-size: 16px;}
.lead1 {font-size:12px;}

/*********Home *******************/
 .h_vid_cont { width: 100%; height:90vh;}
 .h_vid_cont video {width: 100%; height: 100%; object-fit: cover;}

.home_cont_2 { padding:5% 5%;}
.home_cont_2 h2{ font-size:3rem; color: var(--brand_color); font-weight: 400; padding:0 0 0 18%;}
.home_cont_2 h2 span{ font-style:italic; font-weight:500;}
.home_cont_2 p span {font-style:italic; font-weight:700;}

.hcont3 { padding:0% 0 5% 0;} 
.hcont3 img { transition: transform 1.5s; border-radius:8px; cursor: pointer;}
.hcont3 img:hover {transform: scale(1.1); }
.hcont3 h4 {color: var(--brand_color); padding-top:1.5rem; font-weight: 700; cursor: pointer; } 

.hcont4 { padding:5% 5% 5% 5%; background-color: #B4A365;}
.hcont4 h3{ font-size:3rem; font-weight:400; color: var(--white); text-align: center;}
.hcont4 h3 span{ font-style: italic;}

/********* Restaurant ***********/

 .rest_1 { background-image: url('/public/restaurant/bistros.jpg'); background-size: cover; height:100%; padding-top:10%; padding-bottom: 10%;}
 .rest_1 h2 {font-family: 'Great Vibes', cursive; color: var(--white);  font-size:8rem;}
 .rest_1 p {color:#EEEDE8; padding:0 20%;} 

 /************ Contact *************/
 .conthead { background-image: url("/public/foodservices/cp.png"); background-repeat: no-repeat; background-size: cover; background-position:bottom; background-attachment: fixed;}
 .contact_1 .row .col{  background-color:var(--brand_color);  padding:3% 5%; & h2 {color: var(--white); font-size:8rem;}  & p {color: #EEEDE8;} & a{ font-size:18px; text-decoration: none; color:inherit; font-weight: 700;} }
 .contact_2 p a{ font-size:18px; text-decoration: none; color: var(--grey_p);} 
 .contactcontainer { height:100%;}
 .imgcolumn { padding:5% 0% 5% 2%; }
 .contactcontainer img { height:100%; width: 100%;}
 .contactcolumn {  min-height:550px; display: flex; flex-direction: column; justify-content: center;} 
.contactcontainer p a{text-decoration: none; color: inherit; font-weight: bold;}
 .contactcontainer h2{color:var(--white); font-style: italic; font-weight: 600; }
 .contactcontainer p {color: #eeede8;} 
address { color: var(--black); font-size:1.2rem;}
.addr {color:var(--black); font-weight: 700;}
.contactcontainer2 p a{ font-size:18px; text-decoration: none; color: var(--grey_p);} 

/************ Careers Page *****************************/
.careercont1 { 
  background-image: url("/public/career/career_banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  scroll-behavior: smooth;
  transition:all 1.5s ease;
  filter: grayscale(0.9);
}
.careercont1 img { width: 100%; height: 100%;}
.careercont1 h1 { 
  text-shadow: 4px 4px 10px black;
  font-weight:400; 
  font-size: 15rem;  
  text-align: center;  
  color: var(--white);}

.careercont11 { filter: grayscale(0); transition:all 1.5s ease; }

.careercont2 { height:30vh;} 
section .pargraph1 { font-weight: 300; font-size:32px;}
section .pargraph1 span { font-style: italic; font-weight: 600;}
section .pargraph2 { font-weight: 300; line-height: normal;} 
section .pargraph2 span { font-style: italic; font-weight: 600; }
.careercont2col { box-shadow: 0px 20px 40px 6px rgba(0, 0, 0, 0.05); 
  text-align: center; color: var(--black); 
  background-color:var(--white); padding:2% !important;
  z-index:999;
}

.careercont3 {   
  background-image: url(/public/career/pattern3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height:auto;
  scroll-behavior: smooth;}
  

  .careercont4 h2 {color: var(--brand_color); font-size:3rem; font-weight: 400;}
  .paragraph3 { color: var(--black); font-style: italic; font-weight: 600;  }
  .paragraph3 a {color: var(--brand_color);}

  .pattern {   background-image: url(/public/career/pattern.png);
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    scroll-behavior: smooth;
  }

/*************** Our Story *****************/
.ourstory_container_1 { background-color: var(--brand_color_2); height:50vw; }
.ourstory_container_1 h1 { font-size: 10rem; color: var(--brand_dark); text-align: center;}
.ourstory_container_1 div:nth-child(1) { top:120px;}
.ourstory_container_2 { height:30vh;}
.ourstory_container_3 { height: 100%; background-size:cover; object-fit: contain; scroll-behavior: smooth;}
.ourstory_container_3 h2 { font-size: 3rem; color: var(--brand_color); font-weight: 400; padding-left:18%;}
.ourstory_container_3 h2 span {font-style: italic; font-weight: 500;}
.ourstory_container_3 p { font-weight: 300; }
.ourstory_container_3 p span {font-style: italic; font-weight: 600;}
.patt {width: 100%; z-index: -1;}
.ourstory_container_4 {padding:2% 0 2% 0;}
.ourstory_container_4 h2 {color:var(--brand_color); font-size:3rem; font-weight:400;} 
.ourstory_container_4 h2 span {font-style: normal; font-weight:700;}
.ourstory_container_4 p {font-size: 1.3rem; font-weight: 300;} 
.ourstory_container_4 p span{font-weight: 700; font-style: italic;}
.ourstory_container_4 .itl {font-style: italic; font-weight: normal;}

.ourstory_container_6 { background-color: #fafafa; & h4{text-transform:uppercase; color: var(--brand_color); padding:5% 5% 2% 0;} & p{padding:0 5% 0 0;}}
.ourstory_container_6 .service_lines img {transition:all 1.3s; border-radius:8px; filter: grayscale(1);}  
.ourstory_container_6 .service_lines img:hover { transform: scale(1.1); filter: grayscale(0); }
.ourstory_container_6 h2 {  font-weight:400; font-size:3rem; color: var(--brand_color); & span{font-style: italic; font-weight:500;}} 

.wrapper { height:auto; width: auto; overflow: hidden;  border-radius: 8px;}
.wrapper img { height:100%; width: 100%; transition: 1.2s ease;  filter: grayscale(1);}
.wrapper img:hover { transform: scale(1.1); filter: grayscale(0);}
.wrapper1 {
  /* height: fit-content; 
  height: -webkit-fit-content; 
  width: fit-content; width: -webkit-fit-content; */
} 
  .wrapper1 img{ width:auto;}
.Our_story_slider {display: none !important;} 
/*********** Food Services **************/
.FS_cont_1 {background-color: var(--brand_color_2); }
.FS_cont_1 h3{ color:var(--brand_dark); font-size: 3rem; font-weight:400; font-style: normal;} .FS_cont_1 h3 span {font-style: italic;}
.FS_cont_1 p{color: var(--white); font-weight:400; }
.FS_cont_1 p span{font-weight:700; font-style: italic;}
.FS_cont_2 { padding-top: 3rem; padding-bottom: 3rem;}
.FS_cont_2 h2{ font-weight:400; font-size:3rem; color:var(--brand_color); line-height:3.5rem; text-align: center; padding-left: 25%;}
.FS_cont_2 h2 span { font-style: italic; font-weight:500; }
.h33 { color:var(--grey_p); font-size: 2rem; font-weight:400;} 
.para_small {font-size: 1.3rem; color: var(--grey_p);}
.para_small span {font-style: italic; font-weight: 700; }

.paragraph4 { color: var(--brand_color); font-size:1.5rem; font-weight: 300;}
.paragraph4 span { font-style: italic; font-weight: 600;}

.FS_cont_4 {background-color: #fafafa; }
.FS_cont_4 h4{ color:var(--grey_p); }
.FS_cont_4 h2{ color: var(--brand_color); font-weight:400;  font-size:3rem; & span { font-style: italic; font-weight:500;}} 
.FS_cont_4 .pargraph2 {padding:2% 0% 0 0; font-weight:400;}

.FS_cont_6 h2 {color: var(--brand_color); font-weight:400; font-size: 3rem; & span{font-style: italic; font-weight: 500;}} 
.FS_cont_6 p span{font-style: italic; font-weight: 700;}
.FS_cont_6 h3 { color: var(--brand_color); font-weight: 700;} 
.FS_cont_6 img { transition: transform 1.5s;}
.FS_cont_6 img:hover { transform:scale(1.1); }


.FS_cont_7 .start-0 {bottom: 5%; z-index:-1;}
.FS_cont_7 { padding-top: 5%; padding-bottom: 3%;} 
#lists .col p { max-width:90%;} #lists img {border-radius:8px;}
.FS_cont_7 img{width: 100%;}  .FandB { padding-left:18%;}
.FS_cont_7 h4{color:var(--grey_p);}
.FS_cont_7 h2{color: var(--brand_color); font-size:3rem; font-weight:700;} 
.FS_cont_7 p {width:80%;} .FS_cont_7 p span {font-weight: 700; font-style: italic;}

.number{font-size:4rem; font-weight:700; line-height:4rem; color: var(--black);}
.sheading {color:var(--black); font-size:2.5rem; font-weight:400;}


.mobile_list {list-style: none; text-decoration: none; background-color: #f6f6f6; padding:5% 0 2% 8%; 
  position:absolute; left:0;  top:0%; z-index: 1111;  box-shadow: 3px 3px #00000055;} 
.mobile_list a {transition: color 0.3s ease;}
.mobile_list li {text-align: left; padding: 5%; transition: 0.5s ease-in;}


.career_mobile h1 { font-size:7rem; padding-top: 25%; color: #fff; text-shadow:4px 4px 10px black; }

@media only screen and (min-width:320px) and (max-width:576px) { 
  /* video {display:none !important;} */
  .wrapper img { filter: grayscale(0); } 
  .ourstory_container_6 .service_lines img { filter: grayscale(0);} 

/***contact ***/ 
.contact_1 {padding-top:0 !important; & p { font-size:18px; } }
.contact_1 .row .col h2{font-size:6rem; line-height:0.8; padding-top:2rem; margin-bottom:0;}
.contact_2 { text-align: center;}
address { font-size: 1rem;}
iframe {width:95%;}  

/******** Our story *****/
.Our_story_slider { display: block !important;}
.Our_story_slider .col {width:65%; margin: auto; }
.Our_story_slider p { font-size:18px;}
.FS_cont_9 .col {width:90%;}
.row2 {display:none;}

.ourstory_container_5 {padding: 15% 10%;}
.ourstory_container_5 p {font-size: 1.1rem; line-height:1.8;}

/*** restaurant ***/
.rest_1 {height:100%; padding-top:10%; padding-bottom:0%; padding-left:5%; padding-right:5%;}
.rest_1 h2{font-size:3.5rem; } 
.rest_1 p { padding:1% 2%; font-size: 18px;}

/**** home ***/
.home_cont_2 {padding: 3% 3%; text-align: center;}
.home_cont_2 .row{ display: flex; flex-direction: column; }
.home_cont_2 h2 {font-size: 2rem; padding: 0;}
.home_cont_2 p {font-size: 18px; text-wrap: unset; padding-left:5%; padding-right: 5%;}
/* .hcont3 .row  { flex-wrap: nowrap;  overflow-x: auto; margin-left: 0 !important; margin-right: 0 !important;
& .col {flex-basis:auto; flex-grow: 0; flex-shrink:0; width:70%; overflow: auto; padding-left: 50px;} } */
.hcont3 .row .col > p {font-size:1rem;}
.hcont3 h4 { padding-top:8px;}
.hcont3 .row {display: none;}
.hcont4 h3{ font-size:1.5rem; line-height:1.4;}
.hcont4 { padding: 10%; }

/**** Career ***/
.career_mobile {display:block;}
#careercont1 {display:none !important;}
.careercont1 {height:500px;  
 
}
.careercont1 h1 {font-size:7rem; padding-top: 25%; }
.careercont2 {height: 23vh;}
.careercont2col { padding:5% !important;}
#leaf {width:20%; padding-bottom:0.5rem;}
section .pargraph1 { font-size: large; margin-bottom:0;} 
section .pargraph2 { font-size: large;}
.careercont4 {padding-left:5%; padding-right: 5%;}

.careercont4 h2 { font-size: 2.5rem;}
.paragraph3 { font-size: large; padding-right:0rem !important; padding-left: 0rem !important;}

  /*** header ***/
  header.py-3 { padding-bottom: 1rem !important; padding-top: 1rem !important; justify-content: start !important; padding-left: 30px;}
  header img {position: absolute; left:40%; top: 0;}
  .logomobile {margin:auto;}
  .nav {display: none;}
  .mobile_list {display: block !important; width:50%; text-align: center; min-height:40px; top:88px; padding: 3% 0 3% 6%;}
  .mobile_list li {padding: 4%; transition: all 0.4s ease-out 5s;} .mobile_list li a { font-size:16px;}
  .milogo {display: none !important;}

  /***Footer***/
  footer.p-3 {padding:1rem !important; padding-left: 0 !important;}
  .frow {flex-direction: column-reverse; text-align: center !important;}
  .frow div:last-child {display: flex; justify-content: center;  padding-bottom: 2%; width:35%; & img{width:85%;} }
  .fssai img {width:20%; padding-left: 0; padding-bottom:1rem;}
  .lead {padding-left: 0; margin-bottom:0;}

  /***** Our story ****/
  .ourstory_container_1 { 
    height:50vh;  
    background-image: url("/public/ourstory/noodles.png"); 
    background-repeat: no-repeat; background-size: cover; background-position: center;
    & h1 { font-size:7rem; width: 100%; color: var(--white); } 
    & .row {flex-direction: column;}
    & #chesecake.py-5 { padding:0 12px 0 12px !important;}
    & div:nth-child(1) {top:50px;}
    & img {max-width:50%; display:none;}
  }
  .ourstory_container_2 { display: none;}
  .ourstory_container_6 { 
    padding-bottom:1rem !important;
    padding-top:1rem !important;
    & h2 {font-size: 2rem;} 
    & h4 {font-size: 20px;}
    & .row2 {    
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-left: 0 !important;
      margin-right: 0 !important;
      justify-content:unset !important;}
    & .row2 .col {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      width: 70%;
      overflow: auto;
      padding-left: 50px;
    }
  }
  .leaf_1 { width:15%; padding-top: 0.5rem!important; padding-bottom: 1rem!important;}
  .ourstory_container_3 { 
    & .row {flex-direction: column; text-align: center; padding-top:0rem !important; padding-bottom: 0 !important;} 
    & h2{font-size: 2rem; padding-left:0;} 
    & p{font-size: 18px;} 
    & .py-5{padding-top: 0!important; padding-bottom: 0!important; padding-left:3%; padding-right: 3%;}}
  
  .ourstory_container_4 { padding:20px 12px 0px 12px; & .row{flex-direction: column; text-align: center;} & h2 {font-size: 2rem; padding-bottom: 1rem !important; padding-top:1rem; margin:0 5%;} & p{font-size: 18px; padding:0 3%;} & img{width:95%; border-radius: 8px;}}

/**** Food Services ***/
.FS_cont_1 { 
  & h3{font-size:2rem;} 
  & p {font-size:18px; margin-bottom: 0;} 
  & h3.mb-5{margin-bottom:1rem !important;}
  & .py-5 {padding: 1rem !important; } 
}
.h33 { font-size: 1.5rem;}
.FS_cont_2 {
  padding-top: 1rem; padding-bottom: 1rem;
  & .row {flex-direction: column; padding-top: 0rem!important;}
  & h3 {font-size: 1.5rem; padding-top: 1rem;}
  & h2{ font-size: 2rem; line-height:2.5rem; padding-left: 0;}
  & p {font-size: 18px; text-align: center; padding:0 1.5rem 0 1.5rem !important; }
}
.FS_cont_4 {
  & .row{ flex-direction: column; text-align: center;}
  & .row div:first-child {padding-left: 5%;}
  & h2 {font-size: 2rem;}
  & h4 {margin-bottom: 0;}
  & img { width: 80%; border-radius:8px 8px;}
}
.FS_cont_6 { margin-bottom: 1rem !important; padding-top:1.5rem !important; padding-bottom:0 !important;
  & h2 {font-size: 2rem;} & h3 {font-size:1.5rem;}
  & p {font-size: 18px; padding-left:0 !important; padding-right: 0!important; margin-bottom: 0;}
  /* & h3 {font-size:calc(1.1rem + .5vw);} */
  .array { padding-left:1rem !important; padding-right: 1rem !important;}
  & img { max-width:85%;}
  .row3 {display:none;}
}
.FS_cont_7 { padding-top:0% !important; text-align: center;
 & h2 { padding-bottom: 0 !important;} 
 & .FandB {padding-left:5%;}
 & p {width: 100%; font-size: 18px;}
 & .start-0 {bottom: 50%;} 
}
#lists p{font-size: 16px;}
#lists h2 { font-size:2rem; line-height: 0;}
#lists h3 {font-size: 1.5rem; padding-bottom:5px !important; margin-bottom: 0;} 
.FS_cont_9 { 
  & .row {flex-direction: column; text-align: center;}
  & .wrapper1 {margin: auto; max-width: 75%;} 
  & h3 {font-size:1.8rem;}
 }
.FS_cont_8 { display: none;}

.careercont1 {background-size: contain;height:100%;background-attachment: initial; background-repeat: round;}
.careercont2 { height:100%; padding-left:2rem!important; padding-right:2rem !important; margin-bottom: 10%;}
.careercont2col.position-absolute{position: static !important;}
.careercont2col.translate-middle { transform: none !important;}
.careercont3 {padding-top:0!important;}
/* .careercont1 h1 {padding-top:50%; padding-bottom:18%; } */

}

@media only screen and (min-width:576px) and (max-width:767px) {  
  .Our_story_slider { display: block !important;}
  .Our_story_slider .col {width:70%; margin: auto; }
  .FS_cont_9 .col {width: 80%;}
  .row2 {display:none;}
  .hcont3 .row {display: none;} 
  .wrapper img { filter: grayscale(0); } 
  .ourstory_container_6 .service_lines img { filter: grayscale(0);}

.lead {padding-left:1rem; margin-bottom: 0;} 
.fssai img {padding-left:1rem; width: auto;}
.ft_logo {padding-bottom: 8px;}
.contactcontainer2 .row {flex-direction: column; justify-content: center;}
.contact_1 .row .col { padding: 10% 5%;}
.contact_1 .row .col h2 { font-size: 7rem; line-height:0.6;}
.contact_2 { text-align: center;}
/*** restaurant ***/
.rest_1 {height:100%; padding-top:10%; padding-bottom:0%; padding-left:5%; padding-right: 5%;}
.rest_1 h2{font-size:5rem; } 
.rest_1 p { padding:1% 5%; font-size: 18px;}

/**** home ***/
.home_cont_2 { padding: 3% 3%;text-align: center;}
.home_cont_2 .row{ display: flex; flex-direction: column; }
.home_cont_2 h2 {font-size: 2.5rem; padding: 0;}
.home_cont_2 p {font-size: 18px; text-wrap: unset;}
/* .hcont3 .row  {flex-direction: row; flex-wrap: nowrap; overflow-x: auto;}
.hcont3 .row .col { width:50%; padding-left: 50px;} */
.hcont3 p{font-size: 18px;}

.hcont4 {padding: 10%; & h3{ font-size: 2.5rem;}}

/**** Career ***/
/* .careercont1 {height: 80vh; background-position: center top;} */

/* .careercont1 h1 {font-size:10rem; } */
.career_mobile {display:block;}
#leaf {width:20%;}
section .pargraph1 { font-size: large;} 
.FS_cont_4 .pargraph2 { font-size: large; padding: 2% 0% 2% 0;}
.careercont4 h2 { font-size:3rem;}
.paragraph3 { font-size: large;} 

.career_mobile h1 {font-size:10rem;}
.careercont1 {
  background-size: contain;
  height: 100%;
  background-attachment: initial;
  background-repeat: round; }
  #careercont1 {display: none !important;}
  .careercont2 { height:400px; padding-left:0rem!important; padding-right:0rem !important;}
  .careercont2col.translate-middle {transform: none !important;}
  .careercont2col.position-absolute {position: static !important;}
  .careercont3 {padding-top: 0!important;}

  /** header ***/
  .nav {display: none;}
  .logomobile { margin: auto;}
  .milogo {display: none !important;}
  header.container { max-width:100%;}
  header.py-3 {justify-content: start !important;}
  .hamburgerN {padding-left: 30px;}
  header img {position: absolute; top: 0; left:42%;}
  .mobile_list { display: block !important;  width:50%; text-align: center; min-height:40px; top:88px;}

   /***** Our story ****/
   .ourstory_container_1 { height: 100%; 
    & h1 { font-size:7rem; width: 100%;} 
    & .row {flex-direction: row;}
    & div:nth-child(1) {top:50px;}
    & img {width:100%;}
  }
  .ourstory_container_2 { display: none;}
  .ourstory_container_6 { padding-top:1rem !important; padding-bottom:1rem !important;
    & h2 {font-size: 2.5rem;} 
    & h4 {font-size: 20px;}
    & .row1 img { width: 12%; padding-top: 0.5rem!important;}
    & .row2 {    
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-left: 0 !important;
      margin-right: 0 !important;
      justify-content:unset !important;}
    & .row2 .col {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      width:50%;
      overflow: auto;
      padding-left:50px;
    }
  }
  .service_lines p {font-size: 18px;}
  .ourstory_container_3 { 
    & .row {flex-direction: column; text-align: center; padding-top:1rem !important;} 
    & h2{font-size: 2.5rem; padding-left:0;} 
    & p{font-size:20px;} 
    & .py-5{padding-top: 0!important; padding-bottom: 0!important;}}
   .ourstory_container_4 { padding:20px 12px; 
    & .row{flex-direction: column; text-align: center;} 
    & h2 {font-size: 2rem; padding: 1rem !important;} 
    & p{font-size: 18px;} & img{width:90%; border-radius: 8px;}}
  
   /*** Food Services ******/
   .FS_cont_1 {
    & .col.py-5{padding-top:0 !important; padding-bottom: 0 !important;}
    & h3 {margin-bottom: 10px !important; font-size: 2.5rem;}
    & p {font-size: 18px;}
  }
   .FS_cont_2 {padding-top: 1rem; padding-bottom: 1rem;
    & .row { flex-direction: column; text-align: center; padding-top: 0!important;}
    & h2 {padding-left:0; font-size: 2.5rem; line-height:1.3;}
    & p {font-size:18px;}
   }
   .FS_cont_4 { & .row { flex-direction: column; text-align: center;} & .row div:first-child {padding-left: 5%;} & img{border-radius: 8px; width:80%;}}
   .FS_cont_6 {margin-bottom: 1rem !important;
    padding-top: 1.5rem !important;
    padding-bottom: 0 !important;
   & .row3 {display:none;}
   & p {margin-bottom: 0;}
  } 
   .FS_cont_7 { text-align: center; margin-top: 0!important; padding-bottom: 0%;
    & .FandB {padding-left:5%; padding-right: 5%;}
    & p{width: 100%; padding: 0 2rem;}
    & .start-0 { bottom: 50%;}
  }
   .FS_cont_9 { & .row {flex-direction: column; text-align: center;} 
                & .wrapper1 {margin: auto; max-width: 60%;} 
                & p {padding: 0 2rem;} }
   .FS_cont_8 { display: none;}
  }
.FS_cont_9 .sheading {font-size: 2.1rem;}
#nav-icon1 {display: none;}

.wrapper-container {background-color:var(--banner_object);  height:400px; margin: 5% 0 10% 0;}
.image {width: 350px; position: relative;} 
.col01 {bottom: 40%;}
.col11 {left: 0; bottom:10vh;} .col22{left: 0; top:50%;}
.wrapper p{color: var(--brand_color); font-weight: 700; font-style: italic; font-size:2.3rem;}
.wrapper h3 {font-family: 'Avrile Sans', sans-serif; color: var(--white); font-weight:700; opacity:0; transition: transform 1.5s;}
/* .wrapper img { width: 100%; display: block; margin: auto; border-radius: 8px; transition: transform 1.5s;} */
.content { width: 100%; height: 100%;
position: absolute; top:0; left: 0;
display: flex; justify-content: center; flex-wrap: wrap; flex-direction: column; align-items: center;
}

@media only screen and (min-width:0px) and (max-width:767px) {  
  /* #vid {display:none !important;} */
 }

@media only screen and (min-width:767px) and (max-width:992px) {  
  .milogo {margin: auto;}
  .career_mobile {display:none;}
  .FS_cont_9 {display: none;}
  .FandB {padding-left:14%;}
  .rest_1 {height: 120vh;}
  .rest_1 p { padding:1% 10%; }
  /*** header ***/
  /* .nav {display: none;} */
  .hamburgerN {display: none;} .logomobile{display: none;}
  .mobile_banner {display: none;}
  .ourstory_container_1 {height: 100%;}
  .ourstory_container_2 {height: 10vh;}
  .ourstory_container_4{ max-width: 98%!important; & h2 {font-size: 2.5rem; padding-bottom:0.3rem !important;} }
  .experienced {display: none !important;}
  .FS_cont_4 { & .col{width: 65%; text-align: center;} & img{border-radius: 8px;}}
}
@media only screen and (min-width:767px) { .wrapper1 {  height: fit-content; 
  height: -webkit-fit-content; 
  width: fit-content; width: -webkit-fit-content;}}
@media only screen and (min-width:767px) and (max-width:1024px) { 
  .careercont1 { height:100%; padding:20% 0; background-attachment: unset; background-size: contain;} 
  .careercont1 h1 {font-size: 12rem; padding-bottom: 1rem;}
  .careercont2 {height: 20vh;}
}
@media only screen and (min-width:991px) { 
  .hamburgerN {display: none;}  .logomobile{display: none;}
  .FS_cont_9 {display: none;} 
  .mobile_banner {display: none;}
  .experienced {display:none !important;}
  .career_mobile {display:none;}
}
